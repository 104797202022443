import { ReactNode } from 'react';

import { Props as ButtonProps } from 'components/layout/Button';

export interface Props {
    headline?: string;
    subheadline?: string;
    description?: string;
    subdescription?: string;
    layout?: Layouts;
    children?: ReactNode
    buttonProps?: ButtonProps;
    buttonLabel?: string;
    notFound?: string;
    buttonLearnMore?: string;
    longDescriptionRef?: boolean;
    expanded?: boolean;
    expandable?: boolean;
}

export interface PropsLargeBox {
    address?: string;
    contact?: string;
    openHours?: string;
    closed?: string;
    showOnMap?: string;
    showPhotos?: string;
    monday?: string;
    tuesday?: string;
    wednesday?: string;
    thursday?: string;
    friday?: string;
    saturday?: string;
    sunday?: string;
}

export enum Layouts {
    Center = 'center',
    Left = 'left',
}
