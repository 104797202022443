import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    background: transparent;

    .copy {
        .headline {
            font-size: 1.7em;
            font-weight: 700;
            color: #262626;

            a {
                font-weight: 400;
            }
        }

        .subheadline {
            font-size: 1em;
            font-weight: 400;
            color: #262626;
            margin-top: .5em;

            & > * {
                font-size: 1.5em;
                font-weight: 400;
                color: #262626;
            }
        }

        .description {
            margin-top: 1em;

            &.expandable {
                .show-more-button {
                    display: none;
                }
            }

            div {
                ul {
                    margin: 1em 0 2em;
                    padding: 0;

                    li {
                        text-align: left;
                        padding: 0.2em 0;
                        max-width: 850px;
                        margin: auto;

                        &::marker {
                            color: #BA9449;
                            font-size: 1em;
                        }
                    }
                }

                ol {
                    margin: 1em 0 2em;
                    padding: 0;

                    li {
                        text-align: left;
                        padding: 0.2em 0;
                        max-width: 850px;
                        margin: auto;

                        &::marker {
                            color: #BA9449;
                            font-size: 1em;
                        }
                    }
                }
            }
        }

        &.layout-center {
            text-align: center;
        }

        &.layout-left {
            text-align: left;
        }
    }

    .control {
    }

    @media all and (max-width: ${variables.mobileL}) {
        .copy {
            &.layout-left {
                text-align: left;

                .headline {
                    text-align: left;
                }

                .subheadline {
                    text-align: left;
                }

                .link {
                    justify-content: center;
                }
            }
        }

        .control {
            font-size: 130%;
        }
    }

    @media all and (max-width: ${variables.mobileM}) {
        .copy {
            .description {
                margin-top: 1em;

                &.expandable {
                    &:not(&.expanded) {
                        .description-content {
                            max-height: 176px;
                            overflow: hidden;
                            position: relative;

                            &:after {
                                position: absolute;
                                content: '';
                                bottom: 0;
                                width: 100%;
                                height: 30px;
                                background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 1) 100%);;
                            }
                        }
                    }

                    .show-more-button {
                        display: flex;
                    }
                }
            }
        }
    }
`;
