import Component from './component';
import { Layouts } from './types';

Component.defaultProps = {
    headline: null,
    subheadline: null,
    description: null,
    children: null,
    buttonProps: null,
    buttonLabel: null,
    layout: Layouts.Center,
};

export default Component;
