import { FunctionComponent, useState } from 'react';
import classnames from 'classnames';

import Button, { ButtonElements } from 'components/layout/Button';
import HtmlStyledContent from 'components/layout/HtmlStyledContent';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutDescription: FunctionComponent<Props> = ({
    headline,
    subheadline,
    description,
    subdescription,
    layout,
    children,
    buttonProps,
    buttonLabel,
    longDescriptionRef,
    expanded,
    expandable,
}) => {
    const [expandDescription, setExpandDescription] = useState(null);

    return (
        <StyledComponent className="public-layout-description">
            {(headline || subheadline || description || subdescription) && (
                <div
                    className={classnames(
                        'copy',
                        `layout-${layout}`
                    )}
                >
                    {headline && (
                        <div
                            className="headline"
                            id="desc-headline"
                        >
                            <h2 dangerouslySetInnerHTML={{ __html: headline }} />
                        </div>
                    )}
                    {subheadline && (
                        <div
                            className="subheadline"
                            dangerouslySetInnerHTML={{ __html: subheadline }}
                        />
                    )}
                    <div className={classnames({
                        'description-wrapper': true,
                        'expandable': longDescriptionRef,
                        expanded,
                    })}
                    >
                        {description && (
                            <HtmlStyledContent
                                className={classnames({
                                    'description': true,
                                    expandable,
                                    'expanded': expandDescription,
                                })}
                                html={description}
                                expandDescription={expandDescription}
                                expandOnClick={expandable ? () => setExpandDescription(!expandDescription) : null}
                            />
                        )}
                        {subdescription && (
                            <HtmlStyledContent
                                className="subdescription"
                                html={subdescription}
                            />
                        )}
                    </div>
                </div>
            )}

            {children && (
                <div className="children">
                    {children}
                </div>
            )}


            {buttonLabel && (
                <div className="control">
                    <Button
                        element={ButtonElements.Anchor}
                        {...buttonProps}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            )}

        </StyledComponent>
    );
};

export default PublicLayoutDescription;
